import { default as indexXfh5dfw6piMeta } from "/var/www/stage/qst/pages/index.vue?macro=true";
import { default as indeximIWn15cpDMeta } from "/var/www/stage/qst/pages/information/index.vue?macro=true";
import { default as _91slug_93CEXwYPa31EMeta } from "/var/www/stage/qst/pages/information/subpage/index/[category]/index/[slug].vue?macro=true";
import { default as indexBEbhzmXuURMeta } from "/var/www/stage/qst/pages/information/subpage/index/[category]/index/index.vue?macro=true";
import { default as indexIBBWEeMRV1Meta } from "/var/www/stage/qst/pages/information/subpage/index/[category]/index.vue?macro=true";
import { default as _91slug_934BqPGXozSJMeta } from "/var/www/stage/qst/pages/information/subpage/index/[category]/news/[slug].vue?macro=true";
import { default as indexY5oaWlnYVYMeta } from "/var/www/stage/qst/pages/information/subpage/index/[category]/news/index.vue?macro=true";
import { default as newsLOOEcu2q5eMeta } from "/var/www/stage/qst/pages/information/subpage/index/[category]/news.vue?macro=true";
import { default as index8dH7WZNlSbMeta } from "/var/www/stage/qst/pages/information/subpage/index.vue?macro=true";
import { default as personal_45information_45policyzxkLpHj8kPMeta } from "/var/www/stage/qst/pages/personal-information-policy.vue?macro=true";
import { default as indexXjwMwHlWDbMeta } from "/var/www/stage/qst/pages/prevention/index.vue?macro=true";
import { default as _91slug_93kzqIpL8E6FMeta } from "/var/www/stage/qst/pages/prevention/subpage/index/[category]/index/[slug].vue?macro=true";
import { default as indexT1xfxwPO48Meta } from "/var/www/stage/qst/pages/prevention/subpage/index/[category]/index/index.vue?macro=true";
import { default as indexmgmi0PJLOdMeta } from "/var/www/stage/qst/pages/prevention/subpage/index/[category]/index.vue?macro=true";
import { default as indexfCglewJOXZMeta } from "/var/www/stage/qst/pages/prevention/subpage/index.vue?macro=true";
import { default as privacy_45policyVz5Dehg7uBMeta } from "/var/www/stage/qst/pages/privacy-policy.vue?macro=true";
import { default as acm_452021AWZ2xIJ3VaMeta } from "/var/www/stage/qst/pages/quit/forms/acm-2021.vue?macro=true";
import { default as healthcareStx2MWEU4rMeta } from "/var/www/stage/qst/pages/quit/forms/healthcare.vue?macro=true";
import { default as newsletterQ4KRIdRM5QMeta } from "/var/www/stage/qst/pages/quit/forms/newsletter.vue?macro=true";
import { default as order9UFtAz4ViiMeta } from "/var/www/stage/qst/pages/quit/forms/order.vue?macro=true";
import { default as pharmacy3ORcY1ewHOMeta } from "/var/www/stage/qst/pages/quit/forms/pharmacy.vue?macro=true";
import { default as vapeM49NEfgcWeMeta } from "/var/www/stage/qst/pages/quit/forms/vape.vue?macro=true";
import { default as indexJD4U2CCBqWMeta } from "/var/www/stage/qst/pages/quit/index.vue?macro=true";
import { default as index0JAlqfXayBMeta } from "/var/www/stage/qst/pages/quit/subpage/index/help/index.vue?macro=true";
import { default as personKZttjNR44mMeta } from "/var/www/stage/qst/pages/quit/subpage/index/help/person.vue?macro=true";
import { default as phone9bNuAg53jwMeta } from "/var/www/stage/qst/pages/quit/subpage/index/help/phone.vue?macro=true";
import { default as smsGoPySt88ToMeta } from "/var/www/stage/qst/pages/quit/subpage/index/help/sms.vue?macro=true";
import { default as confidence_resultsPYROMPm5hxMeta } from "/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence_results.js?macro=true";
import { default as confidencenXx2FWPvuoMeta } from "/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence.vue?macro=true";
import { default as _91slug_93iRyj15QfpxMeta } from "/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/[slug].vue?macro=true";
import { default as indexC4helW67juMeta } from "/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/index.vue?macro=true";
import { default as _91slug_93U49Umz11x6Meta } from "/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue?macro=true";
import { default as indexi1jYrRnDWkMeta } from "/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue?macro=true";
import { default as proXE9GnoCCp6Meta } from "/var/www/stage/qst/pages/quit/subpage/index/pro.vue?macro=true";
import { default as indexSZQ0SvXBXlMeta } from "/var/www/stage/qst/pages/quit/subpage/index.vue?macro=true";
import { default as responsabilityxItVbDXgL1Meta } from "/var/www/stage/qst/pages/responsability.vue?macro=true";
import { default as searchJn2SanC09aMeta } from "/var/www/stage/qst/pages/search.vue?macro=true";
import { default as sharejiG0hIubY2Meta } from "/var/www/stage/qst/pages/share.vue?macro=true";
import { default as sitemap2saQ6ntYDcMeta } from "/var/www/stage/qst/pages/sitemap.vue?macro=true";
import { default as component_45stubXNvczp0rOgMeta } from "/var/www/stage/qst/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubXNvczp0rOg } from "/var/www/stage/qst/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: indexXfh5dfw6piMeta?.name ?? "index___fr",
    path: indexXfh5dfw6piMeta?.path ?? "/",
    meta: indexXfh5dfw6piMeta || {},
    alias: indexXfh5dfw6piMeta?.alias || [],
    redirect: indexXfh5dfw6piMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indeximIWn15cpDMeta?.name ?? "information___fr",
    path: indeximIWn15cpDMeta?.path ?? "/je-minforme",
    meta: indeximIWn15cpDMeta || {},
    alias: indeximIWn15cpDMeta?.alias || [],
    redirect: indeximIWn15cpDMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/index.vue").then(m => m.default || m)
  },
  {
    name: index8dH7WZNlSbMeta?.name ?? "information-subpage___en",
    path: index8dH7WZNlSbMeta?.path ?? "/information/subpage",
    meta: index8dH7WZNlSbMeta || {},
    alias: index8dH7WZNlSbMeta?.alias || [],
    redirect: index8dH7WZNlSbMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/subpage/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexIBBWEeMRV1Meta?.name ?? undefined,
    path: indexIBBWEeMRV1Meta?.path ?? ":category()",
    meta: indexIBBWEeMRV1Meta || {},
    alias: indexIBBWEeMRV1Meta?.alias || [],
    redirect: indexIBBWEeMRV1Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93CEXwYPa31EMeta?.name ?? "information-subpage-index-category-index-slug___fr",
    path: _91slug_93CEXwYPa31EMeta?.path ?? "/je-minforme/:post()",
    meta: _91slug_93CEXwYPa31EMeta || {},
    alias: _91slug_93CEXwYPa31EMeta?.alias || [],
    redirect: _91slug_93CEXwYPa31EMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBEbhzmXuURMeta?.name ?? "information-subpage-index-category-index___fr",
    path: indexBEbhzmXuURMeta?.path ?? "/je-minforme/:category()",
    meta: indexBEbhzmXuURMeta || {},
    alias: indexBEbhzmXuURMeta?.alias || [],
    redirect: indexBEbhzmXuURMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/index/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsLOOEcu2q5eMeta?.name ?? undefined,
    path: newsLOOEcu2q5eMeta?.path ?? ":category()/news",
    meta: newsLOOEcu2q5eMeta || {},
    alias: newsLOOEcu2q5eMeta?.alias || [],
    redirect: newsLOOEcu2q5eMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_934BqPGXozSJMeta?.name ?? "information-subpage-index-category-news-slug___fr",
    path: _91slug_934BqPGXozSJMeta?.path ?? "/je-minforme/actualites/:tag()/:post()",
    meta: _91slug_934BqPGXozSJMeta || {},
    alias: _91slug_934BqPGXozSJMeta?.alias || [],
    redirect: _91slug_934BqPGXozSJMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexY5oaWlnYVYMeta?.name ?? "information-subpage-index-category-news___fr",
    path: indexY5oaWlnYVYMeta?.path ?? "/je-minforme/actualites/:tag?",
    meta: indexY5oaWlnYVYMeta || {},
    alias: indexY5oaWlnYVYMeta?.alias || [],
    redirect: indexY5oaWlnYVYMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/news/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: index8dH7WZNlSbMeta?.name ?? "information-subpage___fr",
    path: index8dH7WZNlSbMeta?.path ?? "/information/subpage",
    meta: index8dH7WZNlSbMeta || {},
    alias: index8dH7WZNlSbMeta?.alias || [],
    redirect: index8dH7WZNlSbMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/subpage/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexIBBWEeMRV1Meta?.name ?? undefined,
    path: indexIBBWEeMRV1Meta?.path ?? "/je-minforme/:category()",
    meta: indexIBBWEeMRV1Meta || {},
    alias: indexIBBWEeMRV1Meta?.alias || [],
    redirect: indexIBBWEeMRV1Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93CEXwYPa31EMeta?.name ?? "information-subpage-index-category-index-slug___fr",
    path: _91slug_93CEXwYPa31EMeta?.path ?? ":post()",
    meta: _91slug_93CEXwYPa31EMeta || {},
    alias: _91slug_93CEXwYPa31EMeta?.alias || [],
    redirect: _91slug_93CEXwYPa31EMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBEbhzmXuURMeta?.name ?? "information-subpage-index-category-index___fr",
    path: indexBEbhzmXuURMeta?.path ?? "/je-minforme/:category()",
    meta: indexBEbhzmXuURMeta || {},
    alias: indexBEbhzmXuURMeta?.alias || [],
    redirect: indexBEbhzmXuURMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/index/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsLOOEcu2q5eMeta?.name ?? undefined,
    path: newsLOOEcu2q5eMeta?.path ?? "/je-minforme/actualites/:tag?",
    meta: newsLOOEcu2q5eMeta || {},
    alias: newsLOOEcu2q5eMeta?.alias || [],
    redirect: newsLOOEcu2q5eMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_934BqPGXozSJMeta?.name ?? "information-subpage-index-category-news-slug___fr",
    path: _91slug_934BqPGXozSJMeta?.path ?? "/je-minforme/actualites/:tag()/:post()",
    meta: _91slug_934BqPGXozSJMeta || {},
    alias: _91slug_934BqPGXozSJMeta?.alias || [],
    redirect: _91slug_934BqPGXozSJMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexY5oaWlnYVYMeta?.name ?? "information-subpage-index-category-news___fr",
    path: indexY5oaWlnYVYMeta?.path ?? "/je-minforme/actualites/:tag?",
    meta: indexY5oaWlnYVYMeta || {},
    alias: indexY5oaWlnYVYMeta?.alias || [],
    redirect: indexY5oaWlnYVYMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/news/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: personal_45information_45policyzxkLpHj8kPMeta?.name ?? "personal-information-policy___fr",
    path: personal_45information_45policyzxkLpHj8kPMeta?.path ?? "/politique-des-renseignements-personnels",
    meta: personal_45information_45policyzxkLpHj8kPMeta || {},
    alias: personal_45information_45policyzxkLpHj8kPMeta?.alias || [],
    redirect: personal_45information_45policyzxkLpHj8kPMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/personal-information-policy.vue").then(m => m.default || m)
  },
  {
    name: personal_45information_45policyzxkLpHj8kPMeta?.name ?? "personal-information-policy___en",
    path: personal_45information_45policyzxkLpHj8kPMeta?.path ?? "/personal-information-policy",
    meta: personal_45information_45policyzxkLpHj8kPMeta || {},
    alias: personal_45information_45policyzxkLpHj8kPMeta?.alias || [],
    redirect: personal_45information_45policyzxkLpHj8kPMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/personal-information-policy.vue").then(m => m.default || m)
  },
  {
    name: indexXjwMwHlWDbMeta?.name ?? "prevention___fr",
    path: indexXjwMwHlWDbMeta?.path ?? "/je-previens",
    meta: indexXjwMwHlWDbMeta || {},
    alias: indexXjwMwHlWDbMeta?.alias || [],
    redirect: indexXjwMwHlWDbMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/prevention/index.vue").then(m => m.default || m)
  },
  {
    name: indexfCglewJOXZMeta?.name ?? "prevention-subpage___en",
    path: indexfCglewJOXZMeta?.path ?? "/prevention/subpage",
    meta: indexfCglewJOXZMeta || {},
    alias: indexfCglewJOXZMeta?.alias || [],
    redirect: indexfCglewJOXZMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexmgmi0PJLOdMeta?.name ?? undefined,
    path: indexmgmi0PJLOdMeta?.path ?? ":category()",
    meta: indexmgmi0PJLOdMeta || {},
    alias: indexmgmi0PJLOdMeta?.alias || [],
    redirect: indexmgmi0PJLOdMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index/[category]/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93kzqIpL8E6FMeta?.name ?? "prevention-subpage-index-category-index-slug___fr",
    path: _91slug_93kzqIpL8E6FMeta?.path ?? "/je-previens/:post()",
    meta: _91slug_93kzqIpL8E6FMeta || {},
    alias: _91slug_93kzqIpL8E6FMeta?.alias || [],
    redirect: _91slug_93kzqIpL8E6FMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index/[category]/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexT1xfxwPO48Meta?.name ?? "prevention-subpage-index-category-index___fr",
    path: indexT1xfxwPO48Meta?.path ?? "/je-previens/:category()",
    meta: indexT1xfxwPO48Meta || {},
    alias: indexT1xfxwPO48Meta?.alias || [],
    redirect: indexT1xfxwPO48Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index/[category]/index/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexfCglewJOXZMeta?.name ?? "prevention-subpage___fr",
    path: indexfCglewJOXZMeta?.path ?? "/prevention/subpage",
    meta: indexfCglewJOXZMeta || {},
    alias: indexfCglewJOXZMeta?.alias || [],
    redirect: indexfCglewJOXZMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexmgmi0PJLOdMeta?.name ?? undefined,
    path: indexmgmi0PJLOdMeta?.path ?? "/je-previens/:category()",
    meta: indexmgmi0PJLOdMeta || {},
    alias: indexmgmi0PJLOdMeta?.alias || [],
    redirect: indexmgmi0PJLOdMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index/[category]/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93kzqIpL8E6FMeta?.name ?? "prevention-subpage-index-category-index-slug___fr",
    path: _91slug_93kzqIpL8E6FMeta?.path ?? ":post()",
    meta: _91slug_93kzqIpL8E6FMeta || {},
    alias: _91slug_93kzqIpL8E6FMeta?.alias || [],
    redirect: _91slug_93kzqIpL8E6FMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index/[category]/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexT1xfxwPO48Meta?.name ?? "prevention-subpage-index-category-index___fr",
    path: indexT1xfxwPO48Meta?.path ?? "/je-previens/:category()",
    meta: indexT1xfxwPO48Meta || {},
    alias: indexT1xfxwPO48Meta?.alias || [],
    redirect: indexT1xfxwPO48Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index/[category]/index/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: privacy_45policyVz5Dehg7uBMeta?.name ?? "privacy-policy___fr",
    path: privacy_45policyVz5Dehg7uBMeta?.path ?? "/politique-de-confidentialite",
    meta: privacy_45policyVz5Dehg7uBMeta || {},
    alias: privacy_45policyVz5Dehg7uBMeta?.alias || [],
    redirect: privacy_45policyVz5Dehg7uBMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: acm_452021AWZ2xIJ3VaMeta?.name ?? "quit-forms-acm-2021___fr",
    path: acm_452021AWZ2xIJ3VaMeta?.path ?? "/form/acm-automne-2021",
    meta: acm_452021AWZ2xIJ3VaMeta || {},
    alias: acm_452021AWZ2xIJ3VaMeta?.alias || [],
    redirect: acm_452021AWZ2xIJ3VaMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/forms/acm-2021.vue").then(m => m.default || m)
  },
  {
    name: acm_452021AWZ2xIJ3VaMeta?.name ?? "quit-forms-acm-2021___en",
    path: acm_452021AWZ2xIJ3VaMeta?.path ?? "/form/acm-automne-2021-en",
    meta: acm_452021AWZ2xIJ3VaMeta || {},
    alias: acm_452021AWZ2xIJ3VaMeta?.alias || [],
    redirect: acm_452021AWZ2xIJ3VaMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/forms/acm-2021.vue").then(m => m.default || m)
  },
  {
    name: healthcareStx2MWEU4rMeta?.name ?? "quit-forms-healthcare___fr",
    path: healthcareStx2MWEU4rMeta?.path ?? "/jarrete/acces-professionnels/etablissements-de-sante",
    meta: healthcareStx2MWEU4rMeta || {},
    alias: healthcareStx2MWEU4rMeta?.alias || [],
    redirect: healthcareStx2MWEU4rMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/forms/healthcare.vue").then(m => m.default || m)
  },
  {
    name: healthcareStx2MWEU4rMeta?.name ?? "quit-forms-healthcare___en",
    path: healthcareStx2MWEU4rMeta?.path ?? "/iquitnow/access/health-facilities",
    meta: healthcareStx2MWEU4rMeta || {},
    alias: healthcareStx2MWEU4rMeta?.alias || [],
    redirect: healthcareStx2MWEU4rMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/forms/healthcare.vue").then(m => m.default || m)
  },
  {
    name: newsletterQ4KRIdRM5QMeta?.name ?? "quit-forms-newsletter___fr",
    path: newsletterQ4KRIdRM5QMeta?.path ?? "/cqts/infolettre",
    meta: newsletterQ4KRIdRM5QMeta || {},
    alias: newsletterQ4KRIdRM5QMeta?.alias || [],
    redirect: newsletterQ4KRIdRM5QMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/forms/newsletter.vue").then(m => m.default || m)
  },
  {
    name: newsletterQ4KRIdRM5QMeta?.name ?? "quit-forms-newsletter___en",
    path: newsletterQ4KRIdRM5QMeta?.path ?? "/cqts/infolettre-en",
    meta: newsletterQ4KRIdRM5QMeta || {},
    alias: newsletterQ4KRIdRM5QMeta?.alias || [],
    redirect: newsletterQ4KRIdRM5QMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/forms/newsletter.vue").then(m => m.default || m)
  },
  {
    name: order9UFtAz4ViiMeta?.name ?? "quit-forms-order___fr",
    path: order9UFtAz4ViiMeta?.path ?? "/cqts/commande-jarrete",
    meta: order9UFtAz4ViiMeta || {},
    alias: order9UFtAz4ViiMeta?.alias || [],
    redirect: order9UFtAz4ViiMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/forms/order.vue").then(m => m.default || m)
  },
  {
    name: order9UFtAz4ViiMeta?.name ?? "quit-forms-order___en",
    path: order9UFtAz4ViiMeta?.path ?? "/cqts/commande-jarrete-en",
    meta: order9UFtAz4ViiMeta || {},
    alias: order9UFtAz4ViiMeta?.alias || [],
    redirect: order9UFtAz4ViiMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/forms/order.vue").then(m => m.default || m)
  },
  {
    name: pharmacy3ORcY1ewHOMeta?.name ?? "quit-forms-pharmacy___fr",
    path: pharmacy3ORcY1ewHOMeta?.path ?? "/jarrete/acces-professionnels/pharmacies",
    meta: pharmacy3ORcY1ewHOMeta || {},
    alias: pharmacy3ORcY1ewHOMeta?.alias || [],
    redirect: pharmacy3ORcY1ewHOMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/forms/pharmacy.vue").then(m => m.default || m)
  },
  {
    name: pharmacy3ORcY1ewHOMeta?.name ?? "quit-forms-pharmacy___en",
    path: pharmacy3ORcY1ewHOMeta?.path ?? "/iquitnow/access/pharmacies",
    meta: pharmacy3ORcY1ewHOMeta || {},
    alias: pharmacy3ORcY1ewHOMeta?.alias || [],
    redirect: pharmacy3ORcY1ewHOMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/forms/pharmacy.vue").then(m => m.default || m)
  },
  {
    name: vapeM49NEfgcWeMeta?.name ?? "quit-forms-vape___fr",
    path: vapeM49NEfgcWeMeta?.path ?? "/form/envers-vape-anxiete",
    meta: vapeM49NEfgcWeMeta || {},
    alias: vapeM49NEfgcWeMeta?.alias || [],
    redirect: vapeM49NEfgcWeMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/forms/vape.vue").then(m => m.default || m)
  },
  {
    name: vapeM49NEfgcWeMeta?.name ?? "quit-forms-vape___en",
    path: vapeM49NEfgcWeMeta?.path ?? "/form/envers-vape-anxiete-en",
    meta: vapeM49NEfgcWeMeta || {},
    alias: vapeM49NEfgcWeMeta?.alias || [],
    redirect: vapeM49NEfgcWeMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/forms/vape.vue").then(m => m.default || m)
  },
  {
    name: indexJD4U2CCBqWMeta?.name ?? "quit___fr",
    path: indexJD4U2CCBqWMeta?.path ?? "/jarrete",
    meta: indexJD4U2CCBqWMeta || {},
    alias: indexJD4U2CCBqWMeta?.alias || [],
    redirect: indexJD4U2CCBqWMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/index.vue").then(m => m.default || m)
  },
  {
    name: indexJD4U2CCBqWMeta?.name ?? "quit___en",
    path: indexJD4U2CCBqWMeta?.path ?? "/iquitnow",
    meta: indexJD4U2CCBqWMeta || {},
    alias: indexJD4U2CCBqWMeta?.alias || [],
    redirect: indexJD4U2CCBqWMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/index.vue").then(m => m.default || m)
  },
  {
    name: indexSZQ0SvXBXlMeta?.name ?? "quit-subpage___en",
    path: indexSZQ0SvXBXlMeta?.path ?? "/quit/subpage",
    meta: indexSZQ0SvXBXlMeta || {},
    alias: indexSZQ0SvXBXlMeta?.alias || [],
    redirect: indexSZQ0SvXBXlMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index.vue").then(m => m.default || m),
    children: [
  {
    name: index0JAlqfXayBMeta?.name ?? "quit-subpage-index-help___fr",
    path: index0JAlqfXayBMeta?.path ?? "/jarrete/jai-besoin-daide",
    meta: index0JAlqfXayBMeta || {},
    alias: index0JAlqfXayBMeta?.alias || [],
    redirect: index0JAlqfXayBMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/index.vue").then(m => m.default || m)
  },
  {
    name: index0JAlqfXayBMeta?.name ?? "quit-subpage-index-help___en",
    path: index0JAlqfXayBMeta?.path ?? "/iquitnow/i-need-help",
    meta: index0JAlqfXayBMeta || {},
    alias: index0JAlqfXayBMeta?.alias || [],
    redirect: index0JAlqfXayBMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/index.vue").then(m => m.default || m)
  },
  {
    name: personKZttjNR44mMeta?.name ?? "quit-subpage-index-help-person___fr",
    path: personKZttjNR44mMeta?.path ?? "/jarrete/aide-personne",
    meta: personKZttjNR44mMeta || {},
    alias: personKZttjNR44mMeta?.alias || [],
    redirect: personKZttjNR44mMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/person.vue").then(m => m.default || m)
  },
  {
    name: personKZttjNR44mMeta?.name ?? "quit-subpage-index-help-person___en",
    path: personKZttjNR44mMeta?.path ?? "/iquitnow/person-help",
    meta: personKZttjNR44mMeta || {},
    alias: personKZttjNR44mMeta?.alias || [],
    redirect: personKZttjNR44mMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/person.vue").then(m => m.default || m)
  },
  {
    name: phone9bNuAg53jwMeta?.name ?? "quit-subpage-index-help-phone___fr",
    path: phone9bNuAg53jwMeta?.path ?? "/jarrete/aide-telephone",
    meta: phone9bNuAg53jwMeta || {},
    alias: phone9bNuAg53jwMeta?.alias || [],
    redirect: phone9bNuAg53jwMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/phone.vue").then(m => m.default || m)
  },
  {
    name: phone9bNuAg53jwMeta?.name ?? "quit-subpage-index-help-phone___en",
    path: phone9bNuAg53jwMeta?.path ?? "/iquitnow/phone-help",
    meta: phone9bNuAg53jwMeta || {},
    alias: phone9bNuAg53jwMeta?.alias || [],
    redirect: phone9bNuAg53jwMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/phone.vue").then(m => m.default || m)
  },
  {
    name: smsGoPySt88ToMeta?.name ?? "quit-subpage-index-help-sms___fr",
    path: smsGoPySt88ToMeta?.path ?? "/jarrete/aide-texto",
    meta: smsGoPySt88ToMeta || {},
    alias: smsGoPySt88ToMeta?.alias || [],
    redirect: smsGoPySt88ToMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/sms.vue").then(m => m.default || m)
  },
  {
    name: smsGoPySt88ToMeta?.name ?? "quit-subpage-index-help-sms___en",
    path: smsGoPySt88ToMeta?.path ?? "/iquitnow/text-help",
    meta: smsGoPySt88ToMeta || {},
    alias: smsGoPySt88ToMeta?.alias || [],
    redirect: smsGoPySt88ToMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/sms.vue").then(m => m.default || m)
  },
  {
    name: confidence_resultsPYROMPm5hxMeta?.name ?? "quit-subpage-index-hesitate-confidence_results___en",
    path: confidence_resultsPYROMPm5hxMeta?.path ?? "hesitate/confidence_results",
    meta: confidence_resultsPYROMPm5hxMeta || {},
    alias: confidence_resultsPYROMPm5hxMeta?.alias || [],
    redirect: confidence_resultsPYROMPm5hxMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence_results.js").then(m => m.default || m)
  },
  {
    name: confidencenXx2FWPvuoMeta?.name ?? "quit-subpage-index-hesitate-confidence___fr",
    path: confidencenXx2FWPvuoMeta?.path ?? "/jarrete/je-me-prepare-a-arreter/votre-confiance",
    meta: confidencenXx2FWPvuoMeta || {},
    alias: confidencenXx2FWPvuoMeta?.alias || [],
    redirect: confidencenXx2FWPvuoMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence.vue").then(m => m.default || m)
  },
  {
    name: confidencenXx2FWPvuoMeta?.name ?? "quit-subpage-index-hesitate-confidence___en",
    path: confidencenXx2FWPvuoMeta?.path ?? "/iquitnow/getting-ready-to-quit/your-confidence",
    meta: confidencenXx2FWPvuoMeta || {},
    alias: confidencenXx2FWPvuoMeta?.alias || [],
    redirect: confidencenXx2FWPvuoMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iRyj15QfpxMeta?.name ?? "quit-subpage-index-hesitate-index-slug___fr",
    path: _91slug_93iRyj15QfpxMeta?.path ?? "/jarrete/je-me-prepare-a-arreter/:post()",
    meta: _91slug_93iRyj15QfpxMeta || {},
    alias: _91slug_93iRyj15QfpxMeta?.alias || [],
    redirect: _91slug_93iRyj15QfpxMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iRyj15QfpxMeta?.name ?? "quit-subpage-index-hesitate-index-slug___en",
    path: _91slug_93iRyj15QfpxMeta?.path ?? "/iquitnow/getting-ready-to-quit/:post()",
    meta: _91slug_93iRyj15QfpxMeta || {},
    alias: _91slug_93iRyj15QfpxMeta?.alias || [],
    redirect: _91slug_93iRyj15QfpxMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexC4helW67juMeta?.name ?? "quit-subpage-index-hesitate-index___fr",
    path: indexC4helW67juMeta?.path ?? "/jarrete/je-me-prepare-a-arreter",
    meta: indexC4helW67juMeta || {},
    alias: indexC4helW67juMeta?.alias || [],
    redirect: indexC4helW67juMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexC4helW67juMeta?.name ?? "quit-subpage-index-hesitate-index___en",
    path: indexC4helW67juMeta?.path ?? "/iquitnow/getting-ready-to-quit",
    meta: indexC4helW67juMeta || {},
    alias: indexC4helW67juMeta?.alias || [],
    redirect: indexC4helW67juMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U49Umz11x6Meta?.name ?? "quit-subpage-index-hesitate-obstacles-slug___fr",
    path: _91slug_93U49Umz11x6Meta?.path ?? "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/:slug()",
    meta: _91slug_93U49Umz11x6Meta || {},
    alias: _91slug_93U49Umz11x6Meta?.alias || [],
    redirect: _91slug_93U49Umz11x6Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U49Umz11x6Meta?.name ?? "quit-subpage-index-hesitate-obstacles-slug___en",
    path: _91slug_93U49Umz11x6Meta?.path ?? "/iquitnow/getting-ready-to-quit/mental-roadblocks/:slug()",
    meta: _91slug_93U49Umz11x6Meta || {},
    alias: _91slug_93U49Umz11x6Meta?.alias || [],
    redirect: _91slug_93U49Umz11x6Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexi1jYrRnDWkMeta?.name ?? "quit-subpage-index-hesitate-obstacles___fr",
    path: indexi1jYrRnDWkMeta?.path ?? "/jarrete/je-me-prepare-a-arreter/pensees-obstacles",
    meta: indexi1jYrRnDWkMeta || {},
    alias: indexi1jYrRnDWkMeta?.alias || [],
    redirect: indexi1jYrRnDWkMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue").then(m => m.default || m)
  },
  {
    name: indexi1jYrRnDWkMeta?.name ?? "quit-subpage-index-hesitate-obstacles___en",
    path: indexi1jYrRnDWkMeta?.path ?? "/iquitnow/getting-ready-to-quit/mental-roadblocks",
    meta: indexi1jYrRnDWkMeta || {},
    alias: indexi1jYrRnDWkMeta?.alias || [],
    redirect: indexi1jYrRnDWkMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue").then(m => m.default || m)
  },
  {
    name: proXE9GnoCCp6Meta?.name ?? "quit-subpage-index-pro___fr",
    path: proXE9GnoCCp6Meta?.path ?? "/jarrete/acces-professionnels",
    meta: proXE9GnoCCp6Meta || {},
    alias: proXE9GnoCCp6Meta?.alias || [],
    redirect: proXE9GnoCCp6Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/pro.vue").then(m => m.default || m)
  },
  {
    name: proXE9GnoCCp6Meta?.name ?? "quit-subpage-index-pro___en",
    path: proXE9GnoCCp6Meta?.path ?? "/iquitnow/access-healthcare",
    meta: proXE9GnoCCp6Meta || {},
    alias: proXE9GnoCCp6Meta?.alias || [],
    redirect: proXE9GnoCCp6Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/pro.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexSZQ0SvXBXlMeta?.name ?? "quit-subpage___fr",
    path: indexSZQ0SvXBXlMeta?.path ?? "/quit/subpage",
    meta: indexSZQ0SvXBXlMeta || {},
    alias: indexSZQ0SvXBXlMeta?.alias || [],
    redirect: indexSZQ0SvXBXlMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index.vue").then(m => m.default || m),
    children: [
  {
    name: index0JAlqfXayBMeta?.name ?? "quit-subpage-index-help___fr",
    path: index0JAlqfXayBMeta?.path ?? "/jarrete/jai-besoin-daide",
    meta: index0JAlqfXayBMeta || {},
    alias: index0JAlqfXayBMeta?.alias || [],
    redirect: index0JAlqfXayBMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/index.vue").then(m => m.default || m)
  },
  {
    name: index0JAlqfXayBMeta?.name ?? "quit-subpage-index-help___en",
    path: index0JAlqfXayBMeta?.path ?? "/iquitnow/i-need-help",
    meta: index0JAlqfXayBMeta || {},
    alias: index0JAlqfXayBMeta?.alias || [],
    redirect: index0JAlqfXayBMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/index.vue").then(m => m.default || m)
  },
  {
    name: personKZttjNR44mMeta?.name ?? "quit-subpage-index-help-person___fr",
    path: personKZttjNR44mMeta?.path ?? "/jarrete/aide-personne",
    meta: personKZttjNR44mMeta || {},
    alias: personKZttjNR44mMeta?.alias || [],
    redirect: personKZttjNR44mMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/person.vue").then(m => m.default || m)
  },
  {
    name: personKZttjNR44mMeta?.name ?? "quit-subpage-index-help-person___en",
    path: personKZttjNR44mMeta?.path ?? "/iquitnow/person-help",
    meta: personKZttjNR44mMeta || {},
    alias: personKZttjNR44mMeta?.alias || [],
    redirect: personKZttjNR44mMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/person.vue").then(m => m.default || m)
  },
  {
    name: phone9bNuAg53jwMeta?.name ?? "quit-subpage-index-help-phone___fr",
    path: phone9bNuAg53jwMeta?.path ?? "/jarrete/aide-telephone",
    meta: phone9bNuAg53jwMeta || {},
    alias: phone9bNuAg53jwMeta?.alias || [],
    redirect: phone9bNuAg53jwMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/phone.vue").then(m => m.default || m)
  },
  {
    name: phone9bNuAg53jwMeta?.name ?? "quit-subpage-index-help-phone___en",
    path: phone9bNuAg53jwMeta?.path ?? "/iquitnow/phone-help",
    meta: phone9bNuAg53jwMeta || {},
    alias: phone9bNuAg53jwMeta?.alias || [],
    redirect: phone9bNuAg53jwMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/phone.vue").then(m => m.default || m)
  },
  {
    name: smsGoPySt88ToMeta?.name ?? "quit-subpage-index-help-sms___fr",
    path: smsGoPySt88ToMeta?.path ?? "/jarrete/aide-texto",
    meta: smsGoPySt88ToMeta || {},
    alias: smsGoPySt88ToMeta?.alias || [],
    redirect: smsGoPySt88ToMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/sms.vue").then(m => m.default || m)
  },
  {
    name: smsGoPySt88ToMeta?.name ?? "quit-subpage-index-help-sms___en",
    path: smsGoPySt88ToMeta?.path ?? "/iquitnow/text-help",
    meta: smsGoPySt88ToMeta || {},
    alias: smsGoPySt88ToMeta?.alias || [],
    redirect: smsGoPySt88ToMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/sms.vue").then(m => m.default || m)
  },
  {
    name: confidence_resultsPYROMPm5hxMeta?.name ?? "quit-subpage-index-hesitate-confidence_results___fr",
    path: confidence_resultsPYROMPm5hxMeta?.path ?? "hesitate/confidence_results",
    meta: confidence_resultsPYROMPm5hxMeta || {},
    alias: confidence_resultsPYROMPm5hxMeta?.alias || [],
    redirect: confidence_resultsPYROMPm5hxMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence_results.js").then(m => m.default || m)
  },
  {
    name: confidencenXx2FWPvuoMeta?.name ?? "quit-subpage-index-hesitate-confidence___fr",
    path: confidencenXx2FWPvuoMeta?.path ?? "/jarrete/je-me-prepare-a-arreter/votre-confiance",
    meta: confidencenXx2FWPvuoMeta || {},
    alias: confidencenXx2FWPvuoMeta?.alias || [],
    redirect: confidencenXx2FWPvuoMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence.vue").then(m => m.default || m)
  },
  {
    name: confidencenXx2FWPvuoMeta?.name ?? "quit-subpage-index-hesitate-confidence___en",
    path: confidencenXx2FWPvuoMeta?.path ?? "/iquitnow/getting-ready-to-quit/your-confidence",
    meta: confidencenXx2FWPvuoMeta || {},
    alias: confidencenXx2FWPvuoMeta?.alias || [],
    redirect: confidencenXx2FWPvuoMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iRyj15QfpxMeta?.name ?? "quit-subpage-index-hesitate-index-slug___fr",
    path: _91slug_93iRyj15QfpxMeta?.path ?? "/jarrete/je-me-prepare-a-arreter/:post()",
    meta: _91slug_93iRyj15QfpxMeta || {},
    alias: _91slug_93iRyj15QfpxMeta?.alias || [],
    redirect: _91slug_93iRyj15QfpxMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iRyj15QfpxMeta?.name ?? "quit-subpage-index-hesitate-index-slug___en",
    path: _91slug_93iRyj15QfpxMeta?.path ?? "/iquitnow/getting-ready-to-quit/:post()",
    meta: _91slug_93iRyj15QfpxMeta || {},
    alias: _91slug_93iRyj15QfpxMeta?.alias || [],
    redirect: _91slug_93iRyj15QfpxMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexC4helW67juMeta?.name ?? "quit-subpage-index-hesitate-index___fr",
    path: indexC4helW67juMeta?.path ?? "/jarrete/je-me-prepare-a-arreter",
    meta: indexC4helW67juMeta || {},
    alias: indexC4helW67juMeta?.alias || [],
    redirect: indexC4helW67juMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexC4helW67juMeta?.name ?? "quit-subpage-index-hesitate-index___en",
    path: indexC4helW67juMeta?.path ?? "/iquitnow/getting-ready-to-quit",
    meta: indexC4helW67juMeta || {},
    alias: indexC4helW67juMeta?.alias || [],
    redirect: indexC4helW67juMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U49Umz11x6Meta?.name ?? "quit-subpage-index-hesitate-obstacles-slug___fr",
    path: _91slug_93U49Umz11x6Meta?.path ?? "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/:slug()",
    meta: _91slug_93U49Umz11x6Meta || {},
    alias: _91slug_93U49Umz11x6Meta?.alias || [],
    redirect: _91slug_93U49Umz11x6Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U49Umz11x6Meta?.name ?? "quit-subpage-index-hesitate-obstacles-slug___en",
    path: _91slug_93U49Umz11x6Meta?.path ?? "/iquitnow/getting-ready-to-quit/mental-roadblocks/:slug()",
    meta: _91slug_93U49Umz11x6Meta || {},
    alias: _91slug_93U49Umz11x6Meta?.alias || [],
    redirect: _91slug_93U49Umz11x6Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexi1jYrRnDWkMeta?.name ?? "quit-subpage-index-hesitate-obstacles___fr",
    path: indexi1jYrRnDWkMeta?.path ?? "/jarrete/je-me-prepare-a-arreter/pensees-obstacles",
    meta: indexi1jYrRnDWkMeta || {},
    alias: indexi1jYrRnDWkMeta?.alias || [],
    redirect: indexi1jYrRnDWkMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue").then(m => m.default || m)
  },
  {
    name: indexi1jYrRnDWkMeta?.name ?? "quit-subpage-index-hesitate-obstacles___en",
    path: indexi1jYrRnDWkMeta?.path ?? "/iquitnow/getting-ready-to-quit/mental-roadblocks",
    meta: indexi1jYrRnDWkMeta || {},
    alias: indexi1jYrRnDWkMeta?.alias || [],
    redirect: indexi1jYrRnDWkMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue").then(m => m.default || m)
  },
  {
    name: proXE9GnoCCp6Meta?.name ?? "quit-subpage-index-pro___fr",
    path: proXE9GnoCCp6Meta?.path ?? "/jarrete/acces-professionnels",
    meta: proXE9GnoCCp6Meta || {},
    alias: proXE9GnoCCp6Meta?.alias || [],
    redirect: proXE9GnoCCp6Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/pro.vue").then(m => m.default || m)
  },
  {
    name: proXE9GnoCCp6Meta?.name ?? "quit-subpage-index-pro___en",
    path: proXE9GnoCCp6Meta?.path ?? "/iquitnow/access-healthcare",
    meta: proXE9GnoCCp6Meta || {},
    alias: proXE9GnoCCp6Meta?.alias || [],
    redirect: proXE9GnoCCp6Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/pro.vue").then(m => m.default || m)
  }
]
  },
  {
    name: responsabilityxItVbDXgL1Meta?.name ?? "responsability___fr",
    path: responsabilityxItVbDXgL1Meta?.path ?? "/conditions-utilisation",
    meta: responsabilityxItVbDXgL1Meta || {},
    alias: responsabilityxItVbDXgL1Meta?.alias || [],
    redirect: responsabilityxItVbDXgL1Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/responsability.vue").then(m => m.default || m)
  },
  {
    name: searchJn2SanC09aMeta?.name ?? "search___fr",
    path: searchJn2SanC09aMeta?.path ?? "/recherche",
    meta: searchJn2SanC09aMeta || {},
    alias: searchJn2SanC09aMeta?.alias || [],
    redirect: searchJn2SanC09aMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/search.vue").then(m => m.default || m)
  },
  {
    name: sharejiG0hIubY2Meta?.name ?? "share___fr",
    path: sharejiG0hIubY2Meta?.path ?? "/progression",
    meta: sharejiG0hIubY2Meta || {},
    alias: sharejiG0hIubY2Meta?.alias || [],
    redirect: sharejiG0hIubY2Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/share.vue").then(m => m.default || m)
  },
  {
    name: sharejiG0hIubY2Meta?.name ?? "share___en",
    path: sharejiG0hIubY2Meta?.path ?? "/progress",
    meta: sharejiG0hIubY2Meta || {},
    alias: sharejiG0hIubY2Meta?.alias || [],
    redirect: sharejiG0hIubY2Meta?.redirect,
    component: () => import("/var/www/stage/qst/pages/share.vue").then(m => m.default || m)
  },
  {
    name: sitemap2saQ6ntYDcMeta?.name ?? "sitemap___fr",
    path: sitemap2saQ6ntYDcMeta?.path ?? "/plan-du-site",
    meta: sitemap2saQ6ntYDcMeta || {},
    alias: sitemap2saQ6ntYDcMeta?.alias || [],
    redirect: sitemap2saQ6ntYDcMeta?.redirect,
    component: () => import("/var/www/stage/qst/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/actualites/semaine-pour-un-quebec-sans-tabac-2024",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/avis-non-responsabilite",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/form/formulaire-2",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/form/f",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/acces-professionnels/pharmacie$",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/form/patient-reference-form-from-hosp",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/access/healthcare",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/form/patient-reference-form-from-phar",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/access/pharmacy",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/access$",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/my-plan/exercices",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/member/password/reset",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/access-pharmacy",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/still-hesitating",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/still-hesitating/your-confidence",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblock/afraid-withdrawal",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblocks/afraid-withdrawal",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblock/life-boring",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblocks/life-boring",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblock/stop-without-fattening",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblocks/stop-without-fattening",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblock/health-benefits",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblocks/health-benefits",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/actualites/poursuites-judiciaires/recours-collectifs-quebecois-un-nouveau-chapitre-en",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/actualites/lois-et-reglements/promotion-des-produits-de-vapotage-le-gouvernement",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/actualites/lois-et-reglements/levons-notre-verre-la-2e-saison-des-terrasses-sans-fumee",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/actualites/lois-et-reglements/lancement-de-la-strategie-pour-un-quebec-sans-tabac-2020",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/actualites/lois-et-reglements/cinq-raisons-meconnues-de-hausser-la-taxe-sur-les",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/actualites/lois-et-reglements/adoption-du-projet-de-loi-s-5-la-cigarette-electronique",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/actualites/arret-tabagique/lecole-secondaire-du-rocher-adopte-la-toute-premiere$",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/actualites/arret-tabagique/le-secret-du-controle-de-soi-comportement-sante-et$",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/dangers-sante-cigarette",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/dependance/bienfaits-arretert",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/formes-tabac",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/industrie-tabac/stratégies-marketing-cigarettiers",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/produits-de-vapotage/lois-et-indusrie",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/produits-de-vapotage-et-cigarette-electronique/cest-quoi",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-previens/ecoles-organismes-jeunesse",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-previens/maison",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/deni",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/?profil=adulte",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jeminforme/industrie-tabac;",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarete/aide-personne",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/vos-envies",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/votre-confiance",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensee-obstacle/arreter-de-fumer",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles/arreter-de-fumer",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensee-obstacle/benefices-sante",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles/benefices-sante",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensee-obstacle/craindre-sevrage",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles/craindre-sevrage",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensee-obstacle/dangers-cigarette",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles/dangers-cigarette",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensee-obstacle/grand-pere",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles/grand-pere",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensee-obstacle/panique-cesser-fumer",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles/panique-cesser-fumer",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensee-obstacle/trucs-cesser-fumer",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles/trucs-cesser-fumer",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/actualites/campagnes-mediatiques/semaine-pour-un-quebec-sans-tabac-2017",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/je-minforme/tabas-toutes-formes/types-fumees-tabac",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/cqts/:pathMatch(.*)",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  },
  {
    name: component_45stubXNvczp0rOgMeta?.name ?? undefined,
    path: component_45stubXNvczp0rOgMeta?.path ?? "/node/:pathMatch(.*)",
    meta: component_45stubXNvczp0rOgMeta || {},
    alias: component_45stubXNvczp0rOgMeta?.alias || [],
    redirect: component_45stubXNvczp0rOgMeta?.redirect,
    component: component_45stubXNvczp0rOg
  }
]